/* Any config/functions pertaining to aboveNav ad */
const ABOVE_NAV_PATHS = {
  '/': {
    ccrpos: 2014,
  },
  '/your-library': {
    ccrpos: 2014,
  },
};

// pulls the actual pathway from the url
// automatically appends '/' to the beginning so all paths are accessible
export function getPathInfo(path) {
  const pathname = path || window.location.pathname;
  const splitPath = `/${pathname.split('/')[1]}`;
  return ABOVE_NAV_PATHS[splitPath];
}

// based on if there's a config for the path, return true/false
export function getHasAboveNavAd(path) {
  return !!getPathInfo(path);
}
