/* eslint-disable react-hooks/exhaustive-deps */
import AdWrapper from './primitives/AdWrapper';
import Container from './primitives/Container';
import Dropdown from 'components/Dropdown';
import ExpandDown from 'styles/icons/ExpandDown';
import HeaderFull from './primitives/HeaderFull';
import HeaderLeft from './HeaderLeft';
import HeaderMiddle from './primitives/HeaderMiddle';
import HeaderMobile from './primitives/HeaderMobile';
import HeaderRight from './HeaderRight';
import hub, { E } from 'shared/utils/Hub';
import IconWrapper from './primitives/IconWrapper';
import Logo from 'components/Logo/Logo';
import MagnifyingGlass from 'styles/icons/MagnifyingGlass';
import Menu from './primitives/Menu';
import MenuIcon from 'styles/icons/MenuIcon';
import MenuItem from './primitives/MenuItem';
import NavLink from 'components/NavLink';
import PlaceHolder from './primitives/PlaceHolder';
import ShouldShow from 'components/ShouldShow';
import useAdsContext from 'ads/components/AdsProvider/useAdsContext';
import useEvent from 'hooks/useEvent';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import Wrapper from './primitives/Wrapper';
import { BREAKPOINTS } from 'constants/responsive';
import { closeModal, openLoginModal, openModal } from 'state/UI/actions';
import { ConnectedModals } from 'state/UI/constants';
import {
  DisplayAdSlotContainer,
  HeaderAdDiv,
} from 'ads/components/AdSlotContainer';
import { get, throttle } from 'lodash-es';
import { getHasAboveNavAd } from 'components/Header/aboveNav';
import { getNavList } from 'components/SideNav/helpers';

import { MediaQueries } from 'components/MediaQueries';
import { MENU_ITEM_WIDTH } from './constants';
import { SLOT_DIMENSIONS } from 'ads/constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

type HeaderPropsType = {
  adFree: boolean;
  appMounted: boolean;
  allAccessPreview: boolean;
  customRadioEnabled: boolean;
  darkModeAvailable: boolean;
  displayName: string;
  hasUpgrade: boolean;
  internationalPlaylistRadioEnabled: boolean;
  isLoggedOut: boolean;
  listenInAppVisible: boolean;
  logoutAndStartAnonymousSession: (args: {
    forced: boolean;
    noRedirect: boolean;
  }) => Promise<void>;
  navLinks: any;
  path: string;
  isSearchOpen: boolean;
  showLoginInNav: boolean;
  showPlaylists: boolean;
  showSideNav: () => void;
  subInfoLoaded: boolean;
  upgradeUrl?: string;
};

const Header: React.FunctionComponent<HeaderPropsType> = headerProps => {
  const {
    adFree,
    appMounted,
    allAccessPreview,
    customRadioEnabled,
    darkModeAvailable,
    displayName,
    hasUpgrade,
    isLoggedOut,
    internationalPlaylistRadioEnabled,
    listenInAppVisible,
    logoutAndStartAnonymousSession,
    navLinks,
    path,
    isSearchOpen,
    showLoginInNav,
    showPlaylists,
    showSideNav,
    subInfoLoaded,
    upgradeUrl,
  } = headerProps;
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const [visibleMenuItems, setVisibleMenuItems] = useState<number>(0);
  const translate = useTranslate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const headerLeftRef = useRef<HTMLSpanElement | null>(null);
  const headerRightRef = useRef<HTMLSpanElement | null>(null);

  const dispatch = useDispatch();

  const updateMenuItemsCount = useCallback(
    toShow => {
      const itemsToShow = toShow < 0 ? 0 : toShow;
      if (visibleMenuItems !== itemsToShow) {
        setVisibleMenuItems(itemsToShow);
      }
    },
    [visibleMenuItems],
  );

  const calculateVisibleItems = () => {
    if (isSearchOpen || !subInfoLoaded) return;
    if (window.innerWidth < 768) {
      // 768 is our breakpoint where we switch to mobile menu
      // check that the state has actually changed to prevent an infinite render
      // loop caused by componentDidUpdate calling onResize again
      if (visibleMenuItems !== 0) {
        setVisibleMenuItems(0);
      }
      return;
    }

    if (wrapperRef && headerLeftRef && headerRightRef) {
      // assumes this.state.visibleMenuItems exists
      const wrapperWidth = get(wrapperRef, ['current', 'offsetWidth'], 0);
      const leftWidth = get(headerLeftRef, ['current', 'offsetWidth'], 0);
      const rightWidth = get(headerRightRef, ['current', 'offsetWidth'], 0);

      const containerWidth = wrapperWidth - leftWidth - rightWidth; // container width varies with breakpoints

      const toShow = Math.floor(containerWidth / MENU_ITEM_WIDTH) - 1;
      updateMenuItemsCount(toShow);
    }
  };

  const onResize = useCallback(throttle(calculateVisibleItems, 300), [
    isSearchOpen,
    subInfoLoaded,
  ]);

  const onHamburgerClicked = useCallback(() => {
    showSideNav();
  }, [showSideNav]);

  const getMenuItems = useCallback(
    () =>
      getNavList({
        customRadioEnabled,
        internationalPlaylistRadioEnabled,
        navLinks,
        pageNamePrefix: 'discover',
        showPlaylists: isInitialRender ? false : showPlaylists,
        translate,
      }).map(({ text, ...props }) => (
        <NavLink key={props.dataTest} {...props}>
          {text}
        </NavLink>
      )),
    [
      isInitialRender,
      internationalPlaylistRadioEnabled,
      customRadioEnabled,
      navLinks,
      showPlaylists,
      translate,
    ],
  );

  const onLoginClick = () => {
    dispatch(openLoginModal({ context: 'profile' }));
  };

  useMount(() => {
    hub.on(E.PAGE_RENDERED, () => onResize());
  });

  useEvent({
    event: 'resize',
    handler: onResize,
  });

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      onResize();
    }
  }, [isInitialRender, onResize]);

  useEffect(() => {
    if (!isSearchOpen || subInfoLoaded) {
      setVisibleMenuItems(0);
      onResize();
    }
  }, [onResize, isSearchOpen, subInfoLoaded]);

  useEffect(() => {
    onResize();
  }, [onResize, hasUpgrade]);

  const menuItems = getMenuItems();

  const mainMenuItems = menuItems
    .slice(0, visibleMenuItems)
    .map((menuItem: any) => (
      <MenuItem data-test="header-menu-item" key={menuItem.key}>
        {menuItem}
      </MenuItem>
    ));

  const overflowMenuItems = isInitialRender
    ? []
    : menuItems.slice(visibleMenuItems);

  const leftNavBtn = (
    <IconWrapper>
      {translate('More')}
      <ExpandDown height="24" width="12" />
    </IconWrapper>
  );

  const { isAdsEnabled } = useAdsContext();

  const pushDown =
    appMounted && !adFree && getHasAboveNavAd(path) && !isSearchOpen;

  return (
    <>
      <PlaceHolder
        isAdsEnabled={isAdsEnabled}
        isSearchOpen={isSearchOpen}
        listenInAppVisible={listenInAppVisible}
        pushDown={pushDown}
      />
      <Container
        isAdsEnabled={isAdsEnabled}
        isSearchOpen={isSearchOpen}
        listenInAppVisible={listenInAppVisible}
        pushDown={pushDown}
      >
        {isAdsEnabled && !isSearchOpen ? (
          <AdWrapper>
            <MediaQueries maxWidths={[BREAKPOINTS.LARGE]}>
              {([mobileAd]: [boolean]) => {
                // keys are provided so that we get a new ad on resize
                if (!appMounted) {
                  return null;
                } else if (mobileAd) {
                  return (
                    <DisplayAdSlotContainer
                      ccrpos="2014"
                      ContainerPrimitive={HeaderAdDiv}
                      dimensions={SLOT_DIMENSIONS.HEADER_MOBILE}
                    />
                  );
                } else if (!mobileAd && getHasAboveNavAd(path)) {
                  return (
                    <DisplayAdSlotContainer
                      ccrpos="2014"
                      ContainerPrimitive={HeaderAdDiv}
                      dimensions={SLOT_DIMENSIONS.HEADER_DESKTOP}
                    />
                  );
                }

                return null;
              }}
            </MediaQueries>
          </AdWrapper>
        ) : null}
        <Wrapper id="NavHeader" isSearchOpen={isSearchOpen} pushDown={pushDown}>
          <HeaderMobile data-test="header-mobile" isSearchOpen={isSearchOpen}>
            <IconWrapper
              data-test="header-hamburger"
              onClick={onHamburgerClicked}
            >
              <MenuIcon />
            </IconWrapper>
            <Logo dark onClick={() => dispatch(closeModal())} />
            <IconWrapper
              data-test="header-search"
              onClick={() => {
                dispatch(
                  openModal({
                    id: ConnectedModals.Search,
                    context: null,
                  }),
                );
              }}
            >
              <MagnifyingGlass />
            </IconWrapper>
          </HeaderMobile>
          <HeaderFull
            isSearchOpen={isSearchOpen}
            pushDown={pushDown}
            ref={wrapperRef}
          >
            <span ref={headerLeftRef}>
              <HeaderLeft
                allAccessPreview={allAccessPreview}
                hasUpgrade={hasUpgrade}
                isInitialRender={isInitialRender}
                upgradeUrl={upgradeUrl}
              />
            </span>
            <ShouldShow shouldShow={!isSearchOpen}>
              <HeaderMiddle data-test="header-middle">
                <Menu data-test="header-menu-main">
                  {mainMenuItems}
                  <ShouldShow shouldShow={!!overflowMenuItems.length}>
                    <Dropdown
                      customId="headerMenuDropdown"
                      extendDown
                      role="listitem"
                      triggerBtn={leftNavBtn}
                    >
                      {overflowMenuItems}
                    </Dropdown>
                  </ShouldShow>
                </Menu>
              </HeaderMiddle>
            </ShouldShow>
            <span ref={headerRightRef}>
              <HeaderRight
                darkModeAvailable={darkModeAvailable}
                displayName={displayName}
                isInitialRender={isInitialRender}
                isLoggedOut={isLoggedOut}
                isSearchOpen={isSearchOpen}
                logoutAndStartAnonymousSession={logoutAndStartAnonymousSession}
                onLoginClick={onLoginClick}
                showLoginInNav={showLoginInNav}
              />
            </span>
          </HeaderFull>
        </Wrapper>
      </Container>
    </>
  );
};

export default Header;
